import React, { useState, useEffect } from "react";
import { Button, Input, Form } from "reactstrap";
import PulseLoader from "react-spinners/PulseLoader";
import publicIp from "public-ip";
import iplocation from "iplocation";
import { db, analytics } from "./firebase";
import "./App.css";

import logo from "./assets/img/logo.jpg";

let ipAddress;
let location;
const getIP4 = async () => {
  try {
    ipAddress = await publicIp.v4();
    iplocation(ipAddress, [], (error, res) => {
      location = res;
    });
  } catch (err) {
    console.error("Error getting IP: " + err);
  }
};
getIP4();

let searchValDirty = false;

function App() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [input, setInput] = useState("");
  const [url, setURL] = useState("");
  const [docRef, setDocRef] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const searchVal = params.get("search");
    if (searchVal && !searchValDirty) {
      searchValDirty = true;
      handleSubmit(null, searchVal);
    }
  });

  const handleSubmit = (e, searchVal) => {
    let search;
    if (!searchVal) {
      e.preventDefault();
      const elm = e.target.elements;
      search = elm.url.value.trim();
    } else {
      search = searchVal;
    }

    setError(null);
    setData(null);
    setLoading(true);

    if (!search || search === "" || !search.includes("amazon.com")) {
      setError("Looks like there is a problem with that URL.");
    }

    analytics.logEvent("search", { search_term: search });

    const url = `${process.env.REACT_APP_FUNC_DOMAIN}/getSoldBy?url=${search}`;

    // console.log("URL: " + url);

    fetch(url).then(res => {
      res
        .json()
        .then(data => {
          data.search = search;
          data.create_date = new Date();
          data.ip = ipAddress;
          data.env = process.env.REACT_APP_ENV;
          data.location = location;

          // console.log(JSON.stringify(data, null, 2));

          setData(data);
          setURL(
            `https://www.amazon.com/gp/product/${data.asin}/?tag=trueseller0f-20`
          );
          setLoading(false);
          setInput("");

          if (process.env.REACT_APP_ENV === "prod") {
            db.collection("search")
              .add(data)
              .then(docRef => {
                setDocRef(docRef.id);
              })
              .catch(error => {
                console.error("Error adding document: ", error);
              });
          }
        })
        .catch(err => {
          console.log("No products available");
        });
    });
  };

  const trackOutbound = url => {
    const dataCopy = { ...data };
    dataCopy.image = "";
    const track = { url, docRef, data };

    console.log("Tracker: " + JSON.stringify(track, null, 2));
    analytics.logEvent("outbound_link", track);
  };

  const onHandleChange = e => {
    setInput(e.target.value);
  };

  return (
    <div className="App">
      <header className="App-header">
        <img alt="Logo of TrueSeller" className="logo_img" src={logo} />
        <br />
        <h2>Amazon Product Seller Check</h2>
        <br />
        <br />
        <div className="product_url">
          <Form onSubmit={handleSubmit}>
            <Input
              placeholder="Enter Amazon Product URL"
              id="url"
              value={input}
              onChange={e => onHandleChange(e)}
            />
            <br />
            <Button color="primary" className="btn-lg" type="submit">
              Find Seller
            </Button>
          </Form>
        </div>

        {error || (data && data.error) ? (
          <div>
            <br />
            <br />
            <h2>{error ? error : data.error}</h2>
            <br />
          </div>
        ) : data ? (
          <div className="body_format">
            <br />
            <br />
            <br />
            <h2>{data.productTitle}</h2>
            <br />
            <h3>
              This {data.eBook ? "Kindle book" : "item"} is{" "}
              {!data.isAmazon ? (
                <>
                  <span className="color-yellow">
                    <u>not</u>
                  </span>{" "}
                </>
              ) : (
                ""
              )}
              sold by Amazon. It is{" "}
              {!data.fulfilledByAmazon ? (
                <>
                  <span className="color-yellow">
                    <u>not</u>
                  </span>{" "}
                </>
              ) : (
                ""
              )}
              {data.eBook ? "electronically delivered" : "shipped by Amazon"}.
            </h3>
            <h4>
              Sold By{!data.isAmazon ? " 3rd Party" : ""}:{" "}
              <a
                href={`${data.sellerURL}${
                  data.price === "varies" ? "?" : ""
                }tag=trueseller0f-20`}
                alt={data.seller}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data.seller === "3rd Party" ? "several" : data.seller}
              </a>
            </h4>
            <h4>Price: {data.price}</h4>
            <br />
            <br />
            <span className="top-margin">
              <a
                href={url}
                className="pointer"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button color="secondary" className="btn-lg" type="submit">
                  See on Amazon
                </Button>
              </a>
            </span>
            &nbsp;&nbsp;&nbsp;
            <span className="top-margin">
              <a
                href={url}
                onClick={() => trackOutbound(url)}
                className="pointer"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button color="warning" className="btn-lg" type="submit">
                  Buy Now
                </Button>
              </a>
            </span>
            <br />
            <br />
            <a
              href={url}
              onClick={() => trackOutbound(url)}
              className="pointer"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={data.imageURL}
                className="App-logo"
                alt={data.productTitle}
              />
            </a>
            <br />
            <br />
            <br />
            <br />
            <small>
              <sup>*</sup>Clicking the "See on Amazon" or "Buy Now" helps us
              keep the site running through affiliate links, so please click
              away!
            </small>
            <br />
          </div>
        ) : (
          <>
            <br />
            <br />
            <PulseLoader
              sizeUnit={"px"}
              size={26}
              color={"#BF0603"}
              loading={loading}
            />
          </>
        )}
      </header>
      <footer className="App-footer">
        <h5>
          <a
            href="mailto:support@trueseller.co?subject=Contact"
            className="color-white"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact Us
          </a>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <a
            href="https://twitter.com/TruesellerHQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-twitter"></i>
          </a>{" "}
          <br />
          TrueSeller &copy;&nbsp;{new Date().getFullYear()}
        </h5>
        <br/>
        <h5>
          <a href="https://www.ayrshare.com" alt="Ayrshare Social Media API">Social Media Powered by Ayrshare</a>
        </h5>
      </footer>
    </div>
  );
}

export default App;
